import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Moment from "moment";
import React, { useState, useEffect } from "react";

function Debug() {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    var authOptions = {
      method: "get",
      url: "https://connect.yuukke.betalearnings.com/launch/api/debug/guest/status",
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
    };
    axios(authOptions)
      .then((response) => {
        var result = response.data;
        // console.log(result.data);
        var re = result.data;
        // console.log(re);

        setVideo(re);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <Container>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          padding: "15px",
        }}
      >
        <Table striped>
          <thead>
            <tr>
              <th>Username</th>
              <th>Status</th>
              <th>Created Date</th>
              <th>Modified Date</th>
            </tr>
          </thead>
          <tbody>
            {video &&
              video.map((m) => (
                <tr>
                  <td>{m.username}</td>
                  <td>{m.guest_status ? "success" : "failure"}</td>
                  <td>{Moment(m.createdDate).format("d MMM YYYY")}</td>
                  <td>{Moment(m.modifiedDate).format("d MMM YYYY")}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default Debug;
