import palm from "./Image/hand.png";
import scanner from "./Image/bg-scan.gif";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import AuthuService from "./AuthuService";
import { click } from "@testing-library/user-event/dist/click";

function Event() {
  const [image, setImage] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [clicking, setClick] = useState(false);

  useEffect(() => {
    console.log("videCall useEffect", AuthuService.getUserName());
    if (AuthuService.getUserName() == null) {
      setPopUp(true);
    } else {
      setPopUp(false);
    }
  });
  const setClickImg = () => {
    const timer = setTimeout(() => {
      setClick(false);
     // console.log('This will run after 1 second!')
    }, 1000);

    setClick(true);
    var authOptions = {
      method: "post",
      url: "https://connect.yuukke.betalearnings.com/launch/api/add/guest/status",
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
      data: JSON.stringify({
        username: AuthuService.getUserName(),
        click: true,
      }),
    };
    axios(authOptions)
      .then((response) => {
      
       
      })
      .catch((error) => {
        
        console.log(error);
     });
      return () => clearTimeout(timer);
  };

  const popUpState = () => {
    setPopUp(false);
  };
  return (
    <>
      {/* <Container fluid> */}
      <div class="row" style={{ background: "black" }} onClick={setClickImg}>
        {popUp && <PopUp popUpState={popUpState} />}
        {/* <Row style={{

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', <div class="blink">
        background:"black"
      }}>  */}
        <div
          class="blink_me"
          style={{ color: "white", textAlign: "center", paddingTop: "14px" }}
        >
          {clicking && <>Verifying....</>}
        </div>
        <div class="col-lg-12">
          <Image
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              maxWidth: "104%",
            }}
            src={scanner}
             
          />
          {/* <Image className='palm' onClick={shoot}  src={palm} thumbnail /> */}
          {/* {image} */}

          {/* </Row> */}
          {/* </Container> */}
        </div>
      </div>
    </>
  );
}

const PopUp = (props) => {
  const [data, setData] = useState("");

  const storeData = (e) => {
    setData(e.target.value);
  };

  const setSectionStorage = () => {
    AuthuService.registerAuth(data);
    console.log("videCall hi", AuthuService.getUserName());
    var authOptions = {
      method: "post",
      url: "https://connect.yuukke.betalearnings.com/launch/api/add/guest/status",
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
      data: JSON.stringify({
        username: data,
        click: true,
      }),
    };
    axios(authOptions)
      .then((response) => {
        // if (response.data.status == 1) {
        // } else {
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    props.popUpState();
  };
  return (
    <Modal show="true">
      <Modal.Header>Please Share your  name</Modal.Header>

      <Form
        id="profileForm"
        noValidate
        // validated={this.state.validated}
        onSubmit={setSectionStorage}
      >
        <Modal.Body>
          <Form.Group className="formInput">
            <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
              {/* PleaseShare UserName */}
            </Form.Label>
          </Form.Group>
          <input
            type="text"
            placeholder=""
            name="id"
            id="data"
            onChange={(e) => storeData(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">
            Submit Your Name
            {/* <button onClick={setSectionStorage}></button> */}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Event;
