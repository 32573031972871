import bg from "./Image/bg.jpg";
import "../App.css";
import axios from "axios";
import AuthuService from "./AuthuService";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
//import ReactPlayer from 'react-player'
import React, { useState, useEffect } from "react";
import demoVideo from "./Image/ezgif-3-00588599e5.gif";
import url from "./Image/COUNTDOWN.mp4";
import Image from "react-bootstrap/Image";
import ReactPlayer from "react-player";

function Video() {
  const [urlState, setUrl] = useState(false);  
  const [video, setVideo] = useState("");
 

  useEffect(() => {
    setTimeout(()=>{
        
    
    var authOptions = {
      method: "get",
      url: "https://connect.yuukke.betalearnings.com/launch/api/get/guest/status",
      headers: {
        "Content-Type": "application/json",
      },
      json: true,
    };
    axios(authOptions)
      .then((response) => {
        if (response.data.status == 1) {
          setUrl(true);
          setVideo(
            <video controls autoPlay>
              <source src={url} type="video/mp4" />
            </video>
          );
        } else {
          setUrl(false);
          // setVideo(
          //   <Image style={{
          //     position:'absolute',
          //     width: "100%",
          //     height: "100%",
          //     maxWidth: "104%",
          //      }} src={demoVideo} />
          // );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },50)
  });

  return (
    
      <Container fluid>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            background: "black",
          }}
        >
          {urlState && (
            // <video  muted autoPlay>
            //   <source src={url} type="video/mp4" />
            // </video>
            <video controls autoPlay>
              <source src={url} type="video/mp4" />
            </video>
          )}
          {!urlState && (
            <Image
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                maxWidth: "104%",
              }}
              src={demoVideo}
            />
          )}

          {/* <Image
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              maxWidth: "104%",
            }}
            src={demoVideo}
          /> */}
        </Row>
      </Container>
    
  );
}

export default Video;
