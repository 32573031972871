class AuthService{

    registerAuth(userName){
        console.log("AuthServuce userName",userName);
        localStorage.setItem("userName",userName)
    }
    getUserName(){
        let name = localStorage.getItem("userName");
        return name;
    }
}
export default new AuthService();